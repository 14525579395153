import { gql, useQuery } from '@apollo/client';
import React, { createContext, useContext } from 'react';

const adminQuery = gql`
  query AuthenticatedAdmin {
    authenticatedAdmin {
      id
      email
    }
  }
`;
const AuthenticatedAdminContext = createContext({ authenticatedAdmin: null });
export const useAuthenticatedAdmin = () =>
  useContext(AuthenticatedAdminContext);

const AuthenticatedAdminProvider = ({ children }) => {
  const { data, loading } = useQuery(adminQuery);
  const authenticatedAdmin = data?.authenticatedAdmin;

  if (loading) {
    return null;
  }

  return (
    <AuthenticatedAdminContext.Provider value={{ authenticatedAdmin }}>
      {children}
    </AuthenticatedAdminContext.Provider>
  );
};

export default AuthenticatedAdminProvider;
