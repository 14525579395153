import React from 'react';
import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import map from 'lodash/map';
import { usePublicConfig } from '@pv/common/providers';

export default function Pinned() {
  const theme = useTheme();
  const { publicConfig } = usePublicConfig();
  const loginLinks = publicConfig?.admin?.loginLinks;
  let index = 0;
  return (
    <div style={{ display: 'flex', marginBottom: theme.spacing(2) }}>
      {map(loginLinks, (link, name) => {
        index += 1;
        return (
          <Button
            style={index > 1 ? { marginLeft: theme.spacing(2) } : {}}
            href={link}
            key={name}
            variant="contained"
          >
            Login as {name}
          </Button>
        );
      })}
    </div>
  );
}
