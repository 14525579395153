import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { SentryUser } from '@pv/common/sentry';
import IdleJs from 'idle-js';
import {
  ErrorBoundary,
  FullScreen,
  RemoteRefreshTokenWatcher,
} from '@pv/common/components';
import {
  PublicConfigProvider,
  ServerStateProvider,
  ThemeProvider,
} from '@pv/common/providers';
import { PVSnackbarProvider } from './providers/PVSnackbarProvider';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import Venues from './pages/Venues';
import Venue from './pages/Venue';
import Organization from './pages/Organization';
import Organizations from './pages/Organizations';
import Stars from './pages/Stars';
import Users from './pages/Users';
import User from './pages/User';
import Payments from './pages/Payments';
import { Collections } from './pages/Collections';
import { Collection } from './pages/Collection';
import Analytics from './pages/Analytics';
import AuthenticatedAdminPage from './pages/AuthenticatedAdminPage';
import UnauthenticatedAdminPage from './pages/UnauthenticatedAdminPage';
import AuthenticatedAdminProvider from './providers/AuthenticatedAdminProvider';
import { apiHost } from '@pv/common/api';
const pvEnv = process.env.REACT_APP_PV_ENV as string;
const API_HOST = apiHost(pvEnv);

export default function App() {
  const timer = useRef<IdleJs | null>(null);
  const [hidden, setHidden] = useState(false);
  const [idle, setIdle] = useState(false);

  useEffect(() => {
    timer.current = new IdleJs({
      idle: 1000 * 60 * 10, // 10 mins
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
      onIdle: () => setIdle(true),
      onActive: () => setIdle(false),
      onShow: () => setHidden(false),
      onHide: () => setHidden(true),
    });
    timer.current?.start();
    return () => {
      if (timer.current) {
        timer.current.stop();
      }
    };
  }, []);

  if (idle) {
    return <Stars hidden={hidden} />;
  }

  return (
    <PublicConfigProvider apiHost={API_HOST}>
      <SentryUser />
      <ErrorBoundary>
        <ServerStateProvider apiHost={API_HOST}>
          <AuthenticatedAdminProvider>
            <ThemeProvider>
              <PVSnackbarProvider>
                <RemoteRefreshTokenWatcher />
                <BrowserRouter>
                  <div>
                    <FullScreen style={{ width: '100%' }}>
                      {({ height }: { height: number }) => (
                        <div
                          style={{
                            height,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                          }}
                        >
                          <div>
                            <Routes>
                              <Route
                                path="/"
                                element={<Navigate to="/admin" />}
                              />
                              <Route element={<AuthenticatedAdminPage />}>
                                <Route path="/admin" element={<Home />} />
                                <Route
                                  path="/admin/organizations"
                                  element={<Organizations />}
                                />
                                <Route
                                  path="/admin/organizations/:id/*"
                                  element={<Organization />}
                                />
                                <Route
                                  path="/admin/venues"
                                  element={<Venues />}
                                />

                                <Route
                                  path="/admin/venues/:id/*"
                                  element={<Venue />}
                                >
                                  <Route path=":tab" element={<Outlet />} />
                                </Route>
                                <Route
                                  path="/admin/users"
                                  element={<Users />}
                                />
                                <Route
                                  path="/admin/users/:id/*"
                                  element={<User />}
                                />
                                <Route
                                  path="/admin/payments"
                                  element={<Payments />}
                                />
                                <Route
                                  path="/admin/collections"
                                  element={<Collections />}
                                />
                                <Route
                                  path="/admin/collections/:id"
                                  element={<Collection />}
                                />
                                <Route
                                  path="/admin/analytics"
                                  element={<Analytics />}
                                />
                              </Route>

                              <Route element={<UnauthenticatedAdminPage />}>
                                <Route
                                  path="/admins/sign_in"
                                  element={<SignIn />}
                                />
                              </Route>
                            </Routes>
                          </div>
                        </div>
                      )}
                    </FullScreen>
                  </div>
                </BrowserRouter>
                {/* <DemoAPI.Button type={"primary"} text={"test"} onClick={() => alert("tested")} /> */}
              </PVSnackbarProvider>
            </ThemeProvider>
          </AuthenticatedAdminProvider>
        </ServerStateProvider>
      </ErrorBoundary>
    </PublicConfigProvider>
  );
}
