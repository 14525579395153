import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { usePublicConfig } from '@pv/common/providers';
import { apiHost } from '@pv/common/api';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV as string);

export default () => {
  const { publicConfig } = usePublicConfig();

  const dayUrl = `${API_HOST}${publicConfig?.paths?.adminsAnalyticsDownload}?interval=day&subject_type=Venue`;
  const weekUrl = `${API_HOST}${publicConfig?.paths?.adminsAnalyticsDownload}?interval=week&subject_type=Venue`;
  const monthUrl = `${API_HOST}${publicConfig?.paths?.adminsAnalyticsDownload}?interval=month&subject_type=Venue`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button variant="contained" href={dayUrl}>
          Venue data by day
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" href={weekUrl}>
          Venue data by week
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" href={monthUrl}>
          Venue data by month
        </Button>
      </Grid>
    </Grid>
  );
};
