import React from 'react';
import { gql, useMutation } from '@apollo/client';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';

const rollbackVenueDataImport = gql`
  mutation RollbackVenueDataImport($input: RollbackVenueDataImportInput!) {
    rollbackVenueDataImport(input: $input) {
      venueDataImport {
        id
        createdAt
        updatedAt
        status
        errorMessages
      }
      errors {
        message
      }
    }
  }
`;

interface VenueDataImport {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  errorMessages: string[];
}
interface VenueDataImportsListProps {
  venueDataImports: VenueDataImport[];
}
export const VenueDataImportsList = ({
  venueDataImports,
}: VenueDataImportsListProps) => {
  const [rollbackVenueDataImportMutation] = useMutation(
    rollbackVenueDataImport
  );
  const startRollback = (id: string) => {
    rollbackVenueDataImportMutation({
      variables: {
        input: {
          id: id,
        },
      },
    });
  };

  return (
    <div>
      <TableContainer
        sx={{
          border: '1px solid rgba(0, 0, 0, 0.1)',
          boxSizing: 'border-box',
        }}
      >
        <Table size="small">
          <TableHead
            sx={{
              color: 'rgba(0, 0, 0, 0.54)',
              backgroundColor: 'rgba(34, 34, 34, 0.02)',
            }}
          >
            <TableRow>
              <TableHeaderCell>Id</TableHeaderCell>
              <TableHeaderCell>Created At</TableHeaderCell>
              <TableHeaderCell>Updated At</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell>Error Messages</TableHeaderCell>
              <TableHeaderCell>Actions</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {venueDataImports.map((venueDataImport) => (
              <TableBodyRow key={venueDataImport.id}>
                <TableCell>{venueDataImport.id}</TableCell>
                <TableCell>{venueDataImport.createdAt}</TableCell>
                <TableCell>{venueDataImport.updatedAt}</TableCell>
                <TableCell>{venueDataImport.status}</TableCell>
                <TableCell>{venueDataImport.errorMessages}</TableCell>
                <TableCell>
                  {(venueDataImport.status === 'completed' ||
                    venueDataImport.status === 'failed') && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => startRollback(venueDataImport.id)}
                    >
                      Rollback
                    </Button>
                  )}
                </TableCell>
              </TableBodyRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const TableHeaderCell = ({ children }: any) => {
  return (
    <TableCell>
      <Typography variant="overline">{children}</Typography>
    </TableCell>
  );
};

const TableBodyRow = ({ children }: any) => {
  return (
    <TableRow
      sx={{
        outline: '1px solid rgba(0, 0, 0, 0.01)',
        boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.1) inset',
      }}
    >
      {children}
    </TableRow>
  );
};
