import React, { useState } from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import useMousetrap from 'react-hook-mousetrap';
import AdminMultiSearch from './AdminMultiSearch';
import { apiHost } from '@pv/common/api';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV as string);

const version = process.env.REACT_APP_RELEASE_VERSION;
console.log('RELEASE_VERSION', version);

interface NavigationBarProps {
  authenticatedAdmin: {
    id: number;
    email: string;
  };
}

export default function NavigationBar({
  authenticatedAdmin,
}: NavigationBarProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [multiSearchOpen, setMultiSearchOpen] = useState(false);
  useMousetrap(['command+k'], () => {
    setMultiSearchOpen(true);
  });
  useMousetrap(['esc'], () => {
    setMultiSearchOpen(false);
  });

  const navigateTo = (path: string) => navigate(path);

  const onMultisearchClose = () => {
    setMultiSearchOpen(false);
  };

  return (
    <>
      <AppBar className="admin-app-bar" position="static">
        <Toolbar>
          <Typography variant="h4" style={{ color: 'white', flexShrink: 1 }}>
            PVAdmin
          </Typography>
          {version && (
            <Typography
              style={{
                marginLeft: theme.spacing(2),
                color: 'white',
                flexShrink: 1,
              }}
            >
              v.{version}
            </Typography>
          )}
          <div style={{ flexGrow: 1 }} />
          <Button
            onClick={() => navigateTo('/admin')}
            style={{ color: 'white', margin: theme.spacing(0, 2) }}
          >
            Home
          </Button>
          <Button
            onClick={() => navigateTo('/admin/organizations')}
            style={{ color: 'white', margin: theme.spacing(0, 2) }}
          >
            Organizations
          </Button>
          <Button
            onClick={() => navigateTo('/admin/venues')}
            style={{ color: 'white', margin: theme.spacing(0, 2) }}
          >
            Venues
          </Button>
          <Button
            onClick={() => navigateTo('/admin/users')}
            style={{ color: 'white', margin: theme.spacing(0, 2) }}
          >
            Users
          </Button>
          <Button
            onClick={() => setMultiSearchOpen(true)}
            style={{ color: 'white', margin: theme.spacing(0, 2) }}
          >
            Search (Cmd+K)
          </Button>
          <Button
            onClick={() => navigateTo('/admin/payments')}
            style={{ color: 'white', margin: theme.spacing(0, 2) }}
          >
            Payments
          </Button>
          <Button
            onClick={() => navigateTo('/admin/collections')}
            style={{ color: 'white', margin: theme.spacing(0, 2) }}
          >
            Collections
          </Button>

          <Button
            href={`${API_HOST}/admin/sidekiq`}
            style={{ color: 'white', margin: theme.spacing(0, 2) }}
          >
            Sidekiq
          </Button>
          <Button
            href={`${API_HOST}/oauth/applications`}
            style={{ color: 'white', margin: theme.spacing(0, 2) }}
          >
            Doorkeeper
          </Button>
          <Box>
            <Typography
              style={{
                color: 'white',
                margin: theme.spacing(0, 2),
              }}
            >
              {authenticatedAdmin.email}
            </Typography>
          </Box>
          <IconButton
            className="admin-logout-button"
            color="inherit"
            href={`${API_HOST}/admins/sign_out`}
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AdminMultiSearch open={multiSearchOpen} onClose={onMultisearchClose} />
    </>
  );
}
