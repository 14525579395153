import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Chip,
  Grid,
  Link,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import copyToClipboard from 'copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import LaunchIcon from '@material-ui/icons/Launch';
import { useLocation, useNavigate } from 'react-router-dom';
import map from 'lodash/map';
import first from 'lodash/first';
import { getUIItem, setUIItem } from '@pv/common/utils';
import { PageLoading } from '@pv/common/components';

const useStyles = makeStyles((theme) => ({
  name: {
    margin: theme.spacing(2),
  },
  tabWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const userQuery = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      magicLoginLink
      venues {
        id
        name
      }
      organizations {
        id
        name
      }
    }
  }
`;

const VenuesPanel = ({ user }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <div style={{ margin: theme.spacing(2) }}>
      {map(user.venues, (v) => (
        <Grid container spacing={2}>
          <Grid item="xs">
            <Button
              variant="contained"
              onClick={() => navigate(`/admin/venues/${v.id}`)}
            >
              {v.name}
            </Button>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

const InfoPanel = ({ user }) => {
  const theme = useTheme();

  return (
    <div style={{ padding: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {user.firstName} {user.lastName} ({user.email})
        </Grid>
      </Grid>
    </div>
  );
};

const TabPanel = ({ children, value, index }) => {
  if (value !== index) {
    return null;
  }
  return <div>{children}</div>;
};

const tabKey = 'org-tab';
const findInitalTab = () => getUIItem(tabKey) || 'info';

const Organization = ({}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [tab, setTab] = useState(findInitalTab());
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const userId = params.id;
  const variables = { id: userId };
  const { data } = useQuery(userQuery, { variables });
  const user = data?.user;

  const onTabChange = (e, t) => {
    navigate(`/admin/users/${user.id}/${t}`);
    setTab(t);
    setUIItem(tabKey, t);
  };

  useEffect(() => {
    const barePathMatch = location.pathname.match(/\/admin\/users\/(\d+)$/);
    if (barePathMatch) {
      navigate(`/admin/users/${barePathMatch[1]}/info`, { replace: true });
      setTab('info');
    } else if (location.pathname.match(/\/admin\/users\/\d+\/info$/)) {
      setTab('info');
    } else if (location.pathname.match(/\/admin\/users\/\d+\/venues/)) {
      setTab('venues');
    }
  }, [location.pathname]);

  if (!user) {
    return <PageLoading />;
  }

  const onClickLoginAsUser = () => {
    window.open(user.magicLoginLink, 'loginasuser');
  };

  const onClickIdChip = () => {
    copyToClipboard(user.id);
    enqueueSnackbar(`Copied ${user.id} to clipboard`, { variant: 'info' });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            clickable
            onClick={onClickIdChip}
            variant="outlined"
            label={`ID ${user.id}`}
          />
          <Typography className={classes.name} variant="h2">
            User: {user.firstName} {user.lastName}
          </Typography>
        </div>
        <Button onClick={onClickLoginAsUser}>
          <LaunchIcon />
        </Button>
      </div>
      <div style={{ marginBottom: theme.spacing(2) }}>
        <Typography>
          Organization:{' '}
          <Link
            className={classes.link}
            onClick={() =>
              navigate(`/admin/organizations/${first(user?.organizations)?.id}`)
            }
          >
            {first(user.organizations)?.name}
          </Link>
        </Typography>
      </div>
      <Paper>
        <div className={classes.tabWrapper}>
          <div style={{ flexShrink: 1 }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tab || 'info'}
              onChange={onTabChange}
              className={classes.tabs}
            >
              <Tab value="info" label="Info" />
              <Tab value="venues" label="Venues" />
            </Tabs>
          </div>
          <div style={{ width: '100%', flexGrow: 1, minHeight: 500 }}>
            <TabPanel value={tab} index="info">
              <InfoPanel user={user} />
            </TabPanel>
            <TabPanel value={tab} index="venues">
              <VenuesPanel user={user} />
            </TabPanel>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Organization;
