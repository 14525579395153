import { makeStyles } from '@material-ui/styles';
import { danger, success, warn } from '@pv/common/colors';

export const useChipStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  name: {
    margin: theme.spacing(2),
  },
  tabWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {},
  hiddenFileInput: {
    position: 'fixed',
    top: '-100em',
  },
  greenStatusChip: {
    backgroundColor: success.backgroundColor,
    color: success.textColor,
    borderColor: success.textColor,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${success.backgroundColor} !important`,
      color: `${success.textColor} !important`,
      borderColor: `${success.textColor} !important`,
    },
  },
  greenIcon: {
    color: success.textColor,
  },
  warnStatusChip: {
    backgroundColor: warn.backgroundColor,
    color: warn.textColor,
    borderColor: warn.textColor,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${warn.backgroundColor} !important`,
      color: `${warn.textColor} !important`,
      borderColor: `${warn.textColor} !important`,
    },
  },
  warnIcon: {
    color: warn.textColor,
  },
  dangerStatusChip: {
    backgroundColor: danger.backgroundColor,
    color: danger.textColor,
    borderColor: danger.textColor,
    '&:focus, &:active, &:hover': {
      backgroundColor: `${danger.backgroundColor} !important`,
      color: `${danger.textColor} !important`,
      borderColor: `${danger.textColor} !important`,
    },
  },
  dangerIcon: {
    color: danger.textColor,
  },
  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
