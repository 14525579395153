import React from 'react';
import { gql } from '@apollo/client';
import { useMutation } from '@pv/common/hooks';
import { PvDialog } from '@pv/common/components';
import { enqueueSnackbar } from 'notistack';

import { FileUploader } from './FileUploader';

interface VenueDataImport {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  errorMessages: string[];
}
interface VenueDataImportFormProps {
  venueId: string;
  show: boolean;
  onClose: () => void;
  onCompleted: (venueDataImport: VenueDataImport) => void;
}

const createVenueDataImportMutation = gql`
  mutation CreateVenueDataImport($input: CreateVenueDataImportInput!) {
    createVenueDataImport(input: $input) {
      venueDataImport {
        id
        createdAt
        updatedAt
        status
        errorMessages
      }
      errors {
        message
      }
    }
  }
`;

export const VenueDataImportForm = ({
  venueId,
  show,
  onClose,
  onCompleted,
}: VenueDataImportFormProps) => {
  const [eventsCsv, setEventsCsv] = React.useState<any>(null);
  const [eventPaymentsCsv, setEventPaymentsCsv] = React.useState<any>(null);
  const [accountsCsv, setAccountsCsv] = React.useState<any>(null);
  const [leadsCsv, setLeadsCsv] = React.useState<any>(null);
  const [beosZip, setBeosZip] = React.useState<any>(null);
  const [nonTripleseatEventsCsv, setNonTripleseatEventsCsv] =
    React.useState<any>(null);

  const [createVenueDataImport, { loading }] = useMutation(
    createVenueDataImportMutation,
    {
      onNoErrorsCompleted: (response) => {
        const newVenueDataImport =
          response.createVenueDataImport.venueDataImport;
        onCompleted(newVenueDataImport);
        enqueueSnackbar('Data Import Created', {
          variant: 'success',
        });
        onClose();
      },
      onSomeErrorsCompleted: (resp) => {
        const errors = resp.createVenueDataImport.errors;
        errors.forEach((error: { message: string }) => {
          enqueueSnackbar(`Error creating data import: ${error.message}`, {
            variant: 'error',
          });
        });
      },
    }
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const variables = {
      input: {
        venueId: venueId,
        eventsCsv: eventsCsv,
        eventPaymentsCsv: eventPaymentsCsv,
        accountsCsv: accountsCsv,
        leadsCsv: leadsCsv,
        beosZip: beosZip,
        nonTripleseatEventsCsv: nonTripleseatEventsCsv,
      },
    };
    createVenueDataImport({
      variables: variables,
    });
  };

  return (
    <PvDialog
      open={show}
      onClose={onClose}
      title="Create New Data Import"
      confirmButtonText="Import Data"
      confirmButtonColor="primary"
      onConfirm={(e: any) => handleSubmit(e)}
      loading={loading}
    >
      <div>
        <form onSubmit={handleSubmit}>
          <h2>Import Non-Tripleseat Data</h2>
          <FileUploader
            label="Events CSV"
            fileName="nonTripleseatEventsCsv"
            fileType="csv"
            file={nonTripleseatEventsCsv}
            color={'#B1F3D9'}
            onFileAttached={(file) => setNonTripleseatEventsCsv(file)}
          />

          <h2>Import Tripleseat Data</h2>
          <FileUploader
            label="Tripleseat Events CSV"
            fileName="eventsCsv"
            fileType="csv"
            file={eventsCsv}
            color={'primary.yellow'}
            onFileAttached={(file) => setEventsCsv(file)}
          />
          <FileUploader
            label="Tripleseat Event Payments CSV"
            fileName="eventPaymentsCsv"
            fileType="csv"
            file={eventPaymentsCsv}
            color={'primary.orange'}
            onFileAttached={(file) => setEventPaymentsCsv(file)}
          />
          <FileUploader
            label="Tripleseat Accounts CSV"
            fileName="accountsCsv"
            fileType="csv"
            file={accountsCsv}
            color={'primary.pink'}
            onFileAttached={(file) => setAccountsCsv(file)}
          />
          <FileUploader
            label="Tripleseat Leads CSV"
            fileName="leadsCsv"
            fileType="csv"
            file={leadsCsv}
            color={'primary.purple'}
            onFileAttached={(file) => setLeadsCsv(file)}
          />
          <FileUploader
            label="Tripleseat BEOs Zip"
            fileName="beosZip"
            fileType="zip"
            file={beosZip}
            color={'primary.hoverNavy'}
            onFileAttached={(file) => setBeosZip(file)}
          />
        </form>
      </div>
    </PvDialog>
  );
};
