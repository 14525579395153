import { Button, Box } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { PVLoadingButton } from '@pv/common/components';
import { apiHost } from '@pv/common/api';
interface MenuUploadProps {
  venue: { id: string };
}

const API_HOST = apiHost(process.env.REACT_APP_PV_ENV || '');

const useStyles = makeStyles(() => ({
  hiddenFileInput: {
    position: 'fixed',
    top: '-100em',
  },
}));

export const MenuUpload = ({ venue }: MenuUploadProps) => {
  const classes = useStyles();
  const elementId = useRef(uuidv4());
  const [loading, setLoading] = useState(false);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const url = `${API_HOST}/venues/${venue?.id}/upload_menu`;
    const csv = e.target.files[0];
    const formData = new FormData();
    formData.append('csv', csv);
    formData.append('id', venue.id);
    setLoading(true);
    const r = await axios.post(url, formData, { withCredentials: true });
    if (r.data.ok) {
      enqueueSnackbar('Uploaded Menu', { variant: 'success' });
    } else {
      enqueueSnackbar(r.data.error, { variant: 'error' });
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
      }}
    >
      <Button
        href={`${API_HOST}/venues/${venue?.id}/download_menu`}
        variant="contained"
        color="primary"
        style={{ marginRight: '20px' }}
      >
        Download Menu CSV
      </Button>

      <label htmlFor={elementId.current}>
        <PVLoadingButton
          forceLoading={loading}
          variant="contained"
          color="secondary"
          component="span"
        >
          Upload Menu CSV
        </PVLoadingButton>
      </label>
      <input
        id={elementId.current}
        onChange={onChange}
        type="file"
        className={classes.hiddenFileInput}
        accept=".csv"
      />
    </Box>
  );
};

export default MenuUpload;
