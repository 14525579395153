import { Grid, Typography } from '@material-ui/core';
import { disconnectStripeAccountMutation } from '../graphql';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation } from '@pv/common/hooks';
import { Fields, PvDialog } from '@pv/common/components';

export const DisconnectStripeModal = ({
  open,
  onClose,
  venue,
  afterConfirm,
}) => {
  const [confirmation, setConfirmation] = useState('');

  const [disconnectStripeAccount, { loading }] = useMutation(
    disconnectStripeAccountMutation,
    {
      onNoErrorsCompleted: () => {
        enqueueSnackbar(`Disconnected Stripe Account from ${venue.name}`, {
          variant: 'success',
        });
        onClose();
        if (afterConfirm) {
          afterConfirm();
        }
      },
    }
  );

  const onConfirm = () => {
    const venueId = venue.id;
    const input = {
      venueId,
      confirmation,
    };
    const variables = { input };
    disconnectStripeAccount({ variables });
  };

  if (!venue) {
    return null;
  }

  return (
    <PvDialog
      loading={loading}
      title={`Disconnect Stripe Account from ${venue.name}`}
      open={open}
      onClose={onClose}
      confirmDisabled={confirmation !== venue.name}
      confirmButtonText={`Disconnect ${venue.name}`}
      onConfirm={onConfirm}
      maxWidth="sm"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="error" variant="h4">
            Are you sure you want to disconnect stripe account from {venue.name}
            ?
          </Typography>
          <Typography color="error">This cannot be undone.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            If you are really really really sure you want to disconnect this
            venue, type the name below and click confirm.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Fields.PvTextField
            label="Type Venue Name to Continue"
            name="disconnect-venue-confirmation"
            value={confirmation}
            onChange={(e) => setConfirmation(e.target.value)}
          />
        </Grid>
      </Grid>
    </PvDialog>
  );
};
