import { gql, useQuery } from '@apollo/client';
import { Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import map from 'lodash/map';
import max from 'lodash/max';
import toInteger from 'lodash/toInteger';
import startCase from 'lodash/startCase';
import { ResponsiveLine } from '@nivo/line';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import floor from 'lodash/floor';
import { moneyPrint } from '@pv/common/utils';
import { PageLoading } from '@pv/common/components';

const timeSeriesDataQuery = gql`
  query ChartTimeSeriesData(
    $timeSeriesMetricId: ID!
    $interval: TimeSeriesInterval!
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
  ) {
    timeSeriesData(
      timeSeriesMetricId: $timeSeriesMetricId
      interval: $interval
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      time
      value
      interval
    }
  }
`;

const TimeSeriesChart = ({
  timeSeriesMetric,
  interval,
  startDate,
  endDate,
  venue,
}) => {
  const theme = useTheme();
  const variables = {
    timeSeriesMetricId: timeSeriesMetric.id,
    interval,
    startDate,
    endDate,
  };
  const { data: analyticsData, loading } = useQuery(timeSeriesDataQuery, {
    variables,
  });

  let d = analyticsData?.timeSeriesData || [];
  d = map(d, (datum) => ({
    x: moment(datum.time).format('MMM DD, YY'),
    y: datum.value,
  }));

  const xExtent = endDate.diff(startDate, 'days');
  const everyNDays = floor(xExtent / 6);
  const yMax = max(map(d, (d) => d.y));
  const yAxisMax = yMax * 1.25;

  const yFormat = (v) => {
    if (timeSeriesMetric.unit === 'Currency') {
      return moneyPrint(v / 100.0);
    }
    if (timeSeriesMetric.unit === 'Integer') {
      return toInteger(v);
    }
    return v;
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div
          style={{
            height: 250,
            border: '1px solid #eee',
            padding: theme.spacing(2),
            marginBottom: 8,
          }}
        >
          <Typography variant="h5" style={{ marginLeft: theme.spacing(2) }}>
            {startCase(timeSeriesMetric.name)}
          </Typography>
          <AutoSizer>
            {({ width, height }) => (
              <div style={{ width, height }}>
                <ResponsiveLine
                  data={[{ id: timeSeriesMetric.name, data: d }]}
                  width={width}
                  height={height}
                  colors={[theme.palette.primary.main]}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: yAxisMax,
                  }}
                  axisLeft={{
                    tickValues: 2,
                    format: yFormat,
                  }}
                  xScale={{
                    type: 'time',
                    format: '%b %d, %y',
                  }}
                  axisBottom={{
                    tickValues: `every ${everyNDays} days`,
                    format: '%b %d, %y',
                  }}
                  margin={{
                    top: 10,
                    right: 10,
                    bottom: 50,
                    left: 20 + yMax?.toString()?.length * 5,
                  }}
                  useMesh
                />
              </div>
            )}
          </AutoSizer>
        </div>
      </Grid>
    </Grid>
  );
};

export default TimeSeriesChart;
