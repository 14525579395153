import React, { useState, useRef, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import StarfieldAnimation from 'react-starfield-animation';
import { cowsay } from 'cowsayjs';
import sample from 'lodash/sample';
import sky from '../images/sky.jpg';
import stars from '../images/stars.jpg';

const backgrounds = [sky, stars];

const cows = [
  'beavis.zen',
  'blowfish',
  'bong',
  'bud-frogs',
  'bunny',
  'cheese',
  'daemon',
  'default',
  'dragon-and-cow',
  'dragon',
  'elephant-in-snake',
  'elephant',
  'eyes',
  'flaming-sheep',
  'ghostbusters',
  'hellokitty',
  'kiss',
  'kitty',
  'koala',
  'kosh',
  'luke-koala',
  'meow',
  'milk',
  'moofasa',
  'moose',
  'mutilated',
  'ren',
  'satanic',
  'sheep',
  'skeleton',
  'small',
  'stegosaurus',
  'stimpy',
  'supermilker',
  'surgery',
  'three-eyes',
  'turkey',
  'turtle',
  'tux',
  'udder',
  'vader-koala',
  'vader',
  'www',
];

const quotes = [
  'We build exceptional products.\n Exceptional products build exceptional companies. Our ability to build an exceptional product is key to our success - without it, our sales, marketing, and support costs will make it very difficult to scale a marketplace.',

  'We’re customer-obsessed.\n We start with the customer and work backward. We’re obsessed with understanding our customers’ needs and building solutions for them that make their lives easier and their businesses more resilient. Our customers are busy and often anxious about their events businesses. We go above and beyond to make their switch to Perfect Venue as easy as possible. We respond quickly. We personalize responses.',

  'We FITFO.\n We’ll constantly face challenges with no clear solutions, so go and figure it out. ',

  "We play to win.\n We exist to achieve a mission and win. We’re a team first, a family second. There are no extra points for ‘working for work's sake’, we are focused on results. We have a culture of continuous feedback for each other and for our product so that we’re constantly improving.",

  'We’re optimists.\n There will always be many logical and compelling reasons for why we’re screwed - along with every other startup that has ever existed. Success requires being an optimist and persevering joyfully in the face of adversity. Negativity is a self-fulfilling prophecy.',

  'We think like scientists.\n Approach problems with curiosity and let data drive decisions. Quantify problems for example, “5 customers want x” is much better than “a lot of customers want x”. Sometimes we may not be able to make data-driven decisions when we lack data or have a strong conviction about why we should make a different decision.',

  'We all wear the Perfect Venue Patagonia.\n Like a restaurant, without the back of house, there’s no food (product) and without the front of house, there are no customers. Although we have different roles, we are all on the same team with the same mission.',
];

export default ({ hidden }) => {
  const timer = useRef(null);
  const cow = useRef(sample(cows));
  const background = useRef(sample(backgrounds));
  console.log(cow);
  console.log('hidden', hidden);

  const top = 80;
  const left = 80;
  const bottom = 20;
  const right = 20;

  return (
    <>
      <StarfieldAnimation
        numParticles={hidden ? 0 : 300}
        style={{
          background: `url(${background.current})`,
          backgroundSize: 'cover',
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      />
      <div style={{ position: 'absolute', bottom, right }}>
        <Typography
          style={{
            flexGrow: 1,
            padding: 16,
            borderRadius: 16,
            color: '#fff',
          }}
        >
          <pre>{cowsay(sample(quotes), { cow: cow.current })}</pre>
        </Typography>
      </div>

      <div style={{ position: 'absolute', top, left }}>
        <Typography
          variant="h1"
          style={{
            marginBottom: 200,
            fontSize: 80,
            color: '#fff',
          }}
        >
          PVAdmin
        </Typography>
      </div>
    </>
  );
};
