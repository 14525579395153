import moment from 'moment/moment';
import {
  planDefinitionsQuery,
  adminUpdateVenueMutation,
} from '../../../components/VenueDrawer';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState, useMemo } from 'react';
import { useMutation } from '@pv/common/hooks';
import { EditablePanel, Fields } from '@pv/common/components';
import isUrl from 'is-url';
import { normalizeUrl } from '@pv/common/utils';
import startCase from 'lodash/startCase';
import { gql, useQuery } from '@apollo/client';
import { Link } from '@mui/material';

const paymentProviderVals = [['stripe', 'Stripe'], ['square', 'Square']];

const updateVenuePlanMutation = gql`
  mutation UpdateVenuePlanAdmin($input: UpdateVenuePlanInput!) {
    updateVenuePlan(input: $input) {
      errors {
        message
      }
    }
  }
`;

export const InfoPanel = ({ venue }) => {
  const subscription = venue.subscription;
  const [name, setName] = useState(venue.name);
  const [trialEndDate, setTrialEndDate] = useState(
    venue?.trialEndDate && moment(venue?.trialEndDate)
  );
  const [slug, setSlug] = useState(venue.slug);
  const [websiteUrl, setWebsiteUrl] = useState(venue.websiteUrl);
  const [collectionId, setCollectionId] = useState(venue.collection?.id || '');
  const [achEnabled, setAchEnabled] = useState(venue.achEnabled);
  const [defaultPaymentProvider, setDefaultPaymentProvider] = useState(
    venue.defaultPaymentProvider
  );
  const [forceWebsiteError, setForceWebsiteError] = useState(false);
  const [planName, setPlanName] = useState(startCase(subscription?.tier));
  const [expressBookEnabled, setExpressBookEnabled] = useState(
    venue?.expressBookSettings?.enabled
  );
  const [marketplaceEnabled, setMarketplaceEnabled] = useState(
    venue?.expressBookSettings?.marketplaceEnabled
  );

  // =================================================================

  const monthlyRate = (subscription?.monthlyRate || 0) / 100;
  const premiumFeaturesEnabled = subscription?.premiumFeaturesEnabled;
  const basicFeaturesEnabled = subscription?.basicFeaturesEnabled;
  const proFeaturesEnabled = subscription?.proFeaturesEnabled;
  const cardApplicationFeeRate = subscription?.cardApplicationFeeRate;
  const achApplicationFeeRate = subscription?.achApplicationFeeRate;
  const customerStartDate = venue.customerStartedAt || subscription?.startDate;
  const customerStartedAt = customerStartDate
    ? moment(customerStartDate)
    : null;
  const billingInterval = subscription?.billingInterval;

  const { data: adminPlanDefinitions } = useQuery(planDefinitionsQuery);
  const planDefinitions = adminPlanDefinitions?.adminPlanDefinitions;
  const planNameOptions = useMemo(() => {
    const planNames =
      subscription?.tier === 'starter'
        ? ['starter']
        : ['starter', `${subscription?.tier}`];

    return planNames.map((tier) => startCase(tier));
  }, [subscription?.tier]);
  const starterPlan = useMemo(() => {
    return planDefinitions?.find((p) => p.tier === 'starter');
  }, [planDefinitions]);

  const [updateVenuePlan] = useMutation(updateVenuePlanMutation);

  const [updateVenue, { loading }] = useMutation(adminUpdateVenueMutation(), {
    onNoErrorsCompleted: () => {
      enqueueSnackbar('Updated Venue', { variant: 'success' });
    },
  });

  const saveVenue = () => {
    const input = {
      id: venue.id,
      name,
      slug,
      defaultPaymentProvider,
      achEnabled,
      collectionId,
      expressBookEnabled,
      marketplaceEnabled,
    };

    if (trialEndDate) {
      input.trialEndDate = trialEndDate.format('YYYY-MM-DD');
    }

    if (websiteUrl) {
      if (isUrl(websiteUrl)) {
        const url = normalizeUrl(websiteUrl);
        setWebsiteUrl(url);
        input.websiteUrl = url;
      } else {
        setForceWebsiteError(true);
        enqueueSnackbar('Website is invalid', { variant: 'error' });
        return false;
      }
    }
    const variables = { input };
    updateVenue({ variables });
  };

  const downgradeToStarterPlan = async () => {
    const planDefinitionId = starterPlan?.id;
    const input = {
      id: venue.id,
      planDefinitionId,
    };
    const variables = { input };
    updateVenuePlan({ variables });
  };

  const onSave = () => {
    if (planName !== startCase(subscription?.tier)) {
      downgradeToStarterPlan();
    }
    saveVenue();
  };

  const resetValues = () => {
    setName(venue.name || '');
    setPlanName(startCase(venue.subscription?.tier) || '');
    setWebsiteUrl(venue.websiteUrl);
    setSlug(venue.slug);
    setAchEnabled(venue.achEnabled || false);
    setDefaultPaymentProvider(venue.defaultPaymentProvider);
    setCollectionId(venue.collection?.id || '');
    setExpressBookEnabled(venue?.expressBookSettings?.enabled);
    setMarketplaceEnabled(venue?.expressBookSettings?.marketplaceEnabled);
  };

  useEffect(resetValues, [venue]);

  const maybeNormalizeWebsiteUrl = () => {
    if (websiteUrl) {
      setWebsiteUrl(normalizeUrl(websiteUrl));
    }
  };

  const onChangeWebsiteUrl = (e) => {
    const { value } = e.target;
    if (!value) {
      setForceWebsiteError(false);
    }
    setWebsiteUrl(value);
  };

  return (
    <div>
      <EditablePanel
        name="account"
        onSave={onSave}
        onClear={resetValues}
        loading={loading}
        title="Venue Info"
      >
        {({ editing }) => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Fields.PvTextField
                disabled={!editing}
                required
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
              />
            </Grid>
            <Grid item xs={6}>
              <Fields.PvTextField
                disabled={!editing}
                required
                label="Slug"
                value={slug}
                onChange={(e) => setSlug(e.target.value)}
                name="slug"
              />
            </Grid>
            <Grid item xs={6}>
              <Fields.PvTextField
                disabled={!editing}
                label="Website"
                value={websiteUrl}
                onChange={onChangeWebsiteUrl}
                name="website-url"
                forceError={forceWebsiteError}
                onBlur={maybeNormalizeWebsiteUrl}
              />
            </Grid>
            <Grid item xs={6}>
              <Fields.PvDropDown
                disabled={!editing}
                label="Plan Name"
                name="plan"
                value={planName || ''}
                vals={planNameOptions}
                onChange={(e) => setPlanName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              {/* TODO: Properly update field to read-only */}
              <Fields.PvDropDown
                disabled={true}
                label="Billing Interval"
                name="billingInterval"
                value={billingInterval}
                vals={[billingInterval]}
              />
            </Grid>
            <Grid item xs={6}>
              <Fields.PvCurrencyMaskField
                disabled={true}
                value={monthlyRate}
                label="Monthly Rate"
                name="monthly-rate"
              />
            </Grid>
            <Grid item xs={6}>
              {/* TODO: Properly update field to read-only */}
              <Fields.PvIntegerField
                disabled={true}
                label="Card Processing Fee"
                name="card-processing-fee"
                value={cardApplicationFeeRate}
                vals={[cardApplicationFeeRate]}
              />
            </Grid>
            <Grid item xs={6}>
              {/* TODO: Properly update field to read-only */}
              <Fields.PvIntegerField
                disabled={true}
                label="ACH Processing Fee"
                name="ach-processing-fee"
                value={achApplicationFeeRate}
                vals={[achApplicationFeeRate]}
              />
            </Grid>
            <Grid item xs={6}>
              <Fields.PvDateField
                disabled={true}
                clearable
                fullWidth
                label="Customer Start Date"
                name="customer-started-at"
                value={customerStartedAt}
              />
            </Grid>
            <Grid item xs={6}>
              <Fields.PvDropDown
                vals={paymentProviderVals}
                disabled={!editing}
                label="Payment Provider"
                name="payment-provider"
                value={defaultPaymentProvider}
                onChange={(e) => setDefaultPaymentProvider(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              {/* TODO: Properly update field to multi value */}
              <Fields.PvTextField
                disabled={!editing}
                label="Collection Id"
                value={collectionId}
                onChange={(e) => setCollectionId(e.target.value)}
                name="collectionId"
              />
            </Grid>
            <Grid item xs={6}>
              <Fields.PvTextField
                disabled={true}
                label="Connected Stripe Account"
                value={`${venue.stripeAccount?.name}`}
                name="stripe-account-name"
                helperText={
                  <span>
                    {venue.stripeAccount?.stripeAccountId && (
                      <Link
                        href={`https://dashboard.stripe.com/connect/accounts/${venue.stripeAccount?.stripeAccountId}/activity`}
                      >
                        View Stripe Account
                      </Link>
                    )}
                  </span>
                }
              />
            </Grid>
            <Grid item xs={6}>
              {!!trialEndDate && (
                <Fields.PvDateField
                  disabled={!editing}
                  fullWidth
                  label="Trial End Date"
                  name="trial-end-date"
                  value={trialEndDate}
                  onChange={(d) => setTrialEndDate(d)}
                />
              )}
            </Grid>

            <Grid container xs={12}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={true}
                      color="primary"
                      className="basicFeaturesEnabled"
                      name="basicFeaturesEnabled"
                      checked={basicFeaturesEnabled}
                    />
                  }
                  label="Basic Features"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={true}
                      color="primary"
                      className="proFeaturesEnabled"
                      name="proFeaturesEnabled"
                      checked={proFeaturesEnabled}
                    />
                  }
                  label="Pro Features"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={true}
                      color="primary"
                      className="premiumFeaturesEnabled"
                      name="premiumFeaturesEnabled"
                      checked={premiumFeaturesEnabled}
                    />
                  }
                  label="Premium Features"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!editing}
                      color="primary"
                      className="achEnabled"
                      name="achEnabled"
                      checked={achEnabled}
                      onChange={(e) => setAchEnabled(e.target.checked)}
                    />
                  }
                  label="ACH Enabled"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!editing}
                      color="primary"
                      name="expressBookEnabled"
                      checked={expressBookEnabled}
                      onChange={(e) => setExpressBookEnabled(e.target.checked)}
                    />
                  }
                  label="Express Book pre-Beta access enabled"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!editing}
                      color="primary"
                      name="marketplaceEnabled"
                      checked={marketplaceEnabled}
                      onChange={(e) => setMarketplaceEnabled(e.target.checked)}
                    />
                  }
                  label="Marketplace Beta access enabled"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </EditablePanel>
    </div>
  );
};
