import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useMutation } from '@pv/common/hooks';
import { enqueueSnackbar } from 'notistack';
import moment from 'moment';

import {
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@mui/material';

const adminVenueMessagesQuery = gql`
  query adminVenueMessagesQuery($id: ID!) {
    adminVenue(id: $id) {
      id
      name
      outgoingMessagesCount
      incomingMessagesCount
      sentMessagesCount
      blockedMessagesCount
      suspicious
      skipSpamCheck
      subscription {
        id
        status
      }

      messages(limit: 50) {
        id
        sentAt
        sender
        createdAt
        plainTextFromHtml
      }
    }
  }
`;

const adminCancelVenueSubscriptionsMutation = gql`
  mutation adminCancelVenueSubscriptionsMutation(
    $input: AdminCancelVenueSubscriptionsInput!
  ) {
    adminCancelVenueSubscriptions(input: $input) {
      adminVenue {
        id
        suspicious
        skipSpamCheck
        subscription {
          id
          status
        }
      }
    }
  }
`;

const adminUpdateVenueSuspiciousMutation = gql`
  mutation adminUpdateVenueSuspiciousMutation($input: AdminUpdateVenueInput!) {
    adminUpdateVenue(input: $input) {
      adminVenue {
        id
        suspicious
      }
    }
  }
`;

interface Props {
  venueId?: String;
}

interface Message {
  id: string;
  sentAt: string;
  sender: string;
  createdAt: string;
  updatedAt: string;
  plainTextFromHtml: string;
}

export const MessagesPanel = ({ venueId }: Props) => {
  const [venue, setVenue] = React.useState<any>(null);

  const { data, loading, error } = useQuery(adminVenueMessagesQuery, {
    variables: { id: venueId },
    skip: !venueId,
    onCompleted: (data) => {
      setVenue(data.adminVenue);
    },
  });

  useEffect(() => {
    if (data) {
      setVenue(data.adminVenue);
    }
  }, [data]);

  const [cancelVenueSubscriptions] = useMutation(
    adminCancelVenueSubscriptionsMutation,
    {
      onCompleted: (data) => {
        enqueueSnackbar('Subscription cancelled', { variant: 'success' });
      },
      onSomeErrorsCompleted: (errors) => {
        enqueueSnackbar('Error encountered while cancelling subscription', {
          variant: 'error',
        });
      },
    }
  );

  const blockVenue = () => {
    cancelVenueSubscriptions({
      variables: {
        input: {
          id: venueId,
        },
      },
    });
  };

  const [updateVenue] = useMutation(adminUpdateVenueSuspiciousMutation, {
    onCompleted: (data) => {
      enqueueSnackbar('Venue updated', { variant: 'success' });
    },
    onSomeErrorsCompleted: (errors) => {
      enqueueSnackbar('Error encountered while updating venue', {
        variant: 'error',
      });
    },
  });

  const updateVenueIsSuspicious = (isSuspicious: boolean) => {
    updateVenue({
      variables: {
        input: {
          id: venueId,
          suspicious: isSuspicious,
        },
      },
    });
  };

  const setVenueAsSuspicious = () => {
    updateVenueIsSuspicious(true);
  };

  const setVenueAsNotSuspicious = () => {
    updateVenueIsSuspicious(false);
  };

  const formatDate = (date: string) => {
    if (!date) {
      return null;
    }
    return moment(date).format('MM-DD-YYYY  hh:mm:ss a');
  };

  if (!venueId || loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const venueIsSuspectedSpammer = venue?.suspicious && !venue.skipSpamCheck;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <div>
        {venueIsSuspectedSpammer &&
          venue?.subscription?.status !== 'canceled' && (
            <h1>Spammer Suspected</h1>
          )}
        {venueIsSuspectedSpammer &&
          venue?.subscription?.status === 'canceled' && (
            <h1>Spammer Blocked</h1>
          )}
        {!venueIsSuspectedSpammer && <h1>Venue Messages</h1>}
      </div>
      <h2>Summary</h2>
      This venue has attempted to send {venue?.outgoingMessagesCount} messages,
      and has received {venue?.incomingMessagesCount} messages from guests.{' '}
      <br /> Of their{' '}
      {venue?.incomingMessagesCount + venue?.outgoingMessagesCount} total
      messages, we actually sent {venue?.sentMessagesCount}, and{' '}
      {venue?.blockedMessagesCount} have not been sent.
      <h2>Actions</h2>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {!venueIsSuspectedSpammer && (
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <div>
              Is this a spammer? If so, you can stop them from sending more
              messages:
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={setVenueAsSuspicious}
              >
                Block Spammer
              </Button>
            </div>
          </div>
        )}
        {venueIsSuspectedSpammer &&
          venue?.subscription?.status !== 'canceled' && (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              <div>
                We think this venue is probably a spammer. Was that by mistake?
                You can allow them to send more messages, or you can permanently
                block them from using the app:
              </div>
              <div style={{ display: 'flex', gap: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={setVenueAsNotSuspicious}
                >
                  They're legit.
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={blockVenue}
                >
                  Permanently block them.
                </Button>
              </div>
            </div>
          )}
      </div>
      <h2>Messages</h2>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Sender</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Sent At</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {venue?.messages.map((message: Message) => (
              <TableRow key={message.id}>
                <TableCell>{message.sender}</TableCell>
                <TableCell>{formatDate(message.createdAt)}</TableCell>
                <TableCell>{formatDate(message.sentAt) || 'Blocked'}</TableCell>
                <TableCell>{message.plainTextFromHtml}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
