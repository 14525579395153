import { useTheme } from '@material-ui/styles';
import moment from 'moment/moment';
import { useQuery } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import TimeSeriesChart from '../../../components/TimeSeriesChart';
import { usePublicConfig } from '@pv/common/providers';
import React, { useEffect, useState } from 'react';
import { timeSeriesMetricsQuery } from '../graphql';
import { find, join, map, size, startCase } from 'lodash';
import { Fields } from '@pv/common/components';
import { apiHost } from '@pv/common/api';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

export const AnalyticsPanel = ({ venue }) => {
  const { publicConfig } = usePublicConfig();
  const theme = useTheme();
  const [startDate, setStartDate] = useState(moment().subtract(3, 'month'));
  const [endDate, setEndDate] = useState(moment());
  const [interval, setInterval] = useState('day');
  const [timeSeriesMetricIds, setTimeSeriesMetricIds] = useState([]);

  const intervalVals = map(['day', 'week', 'month'], (i) => [i, startCase(i)]);

  const variables = { subjectId: venue.id, subjectType: 'Venue' };
  const { data } = useQuery(timeSeriesMetricsQuery, { variables });
  const allTimeSeriesMetrics = data?.timeSeriesMetrics;

  useEffect(() => {
    setTimeSeriesMetricIds(map(allTimeSeriesMetrics, (tsm) => tsm.id));
  }, [allTimeSeriesMetrics]);

  const timeSeriesMetricVals = map(allTimeSeriesMetrics, (tsm) => [
    tsm.id,
    startCase(tsm.name),
  ]);

  const exportDataUrl = `${API_HOST}${
    publicConfig?.paths?.adminsAnalyticsDownload
  }?${join(
    map(timeSeriesMetricIds, (tsmId) => `time_series_metric_ids[]=${tsmId}`),
    '&'
  )}&start_date=${moment(startDate).format('Y-MM-DD')}&end_date=${moment(
    endDate
  ).format('Y-MM-DD')}&interval=${interval}`;

  const renderValue = (selected) => {
    if (size(selected) < 3) {
      return join(selected, ', ');
    }
    return `${size(selected)} Metrics`;
  };

  return (
    <div style={{ padding: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Fields.PvDateField
                label="Start"
                value={startDate}
                onChange={setStartDate}
              />
            </div>
            <div style={{ marginLeft: theme.spacing(2) }}>
              <Fields.PvDateField
                label="End"
                value={endDate}
                onChange={setEndDate}
              />
            </div>
            <div style={{ marginLeft: theme.spacing(2) }}>
              <Fields.PvDropDown
                label="Interval"
                value={interval}
                onChange={(e) => setInterval(e.target.value)}
                vals={intervalVals}
              />
            </div>
            <div style={{ marginLeft: theme.spacing(2) }}>
              <Fields.PvMultiSelect
                renderValue={renderValue}
                label="Metrics"
                value={timeSeriesMetricIds}
                onChange={(e) => setTimeSeriesMetricIds(e.target.value)}
                vals={timeSeriesMetricVals}
              />
            </div>
            <div style={{ flexGrow: 1 }} />
            <div style={{ marginLeft: theme.spacing(2) }}>
              <Button variant="outlined" color="secondary" href={exportDataUrl}>
                Export
              </Button>
            </div>
          </div>
        </Grid>
        {map(timeSeriesMetricIds, (timeSeriesMetricId) => {
          const timeSeriesMetric = find(
            allTimeSeriesMetrics,
            (tsm) => tsm.id === timeSeriesMetricId
          );
          return (
            <Grid key={timeSeriesMetricId} item xs={12}>
              <TimeSeriesChart
                timeSeriesMetric={timeSeriesMetric}
                venue={venue}
                interval={interval}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
