import { Grid, Typography } from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation } from '@pv/common/hooks';
import { Fields, PvDialog } from '@pv/common/components';
import { removeVenueMutation } from '../graphql';

export const DeleteVenueModal = ({ open, onClose, venue, afterConfirm }) => {
  const [confirmation, setConfirmation] = useState('');

  const [removeVenue, { loading }] = useMutation(removeVenueMutation, {
    onNoErrorsCompleted: () => {
      enqueueSnackbar(`Deleted ${venue.name}`, { variant: 'success' });
      onClose();
      if (afterConfirm) {
        afterConfirm();
      }
    },
  });

  const onConfirm = () => {
    const { id } = venue;
    const input = {
      id,
      confirmation,
    };
    const variables = { input };
    removeVenue({ variables });
  };

  if (!venue) {
    return null;
  }

  return (
    <PvDialog
      loading={loading}
      title={`Delete ${venue.name}`}
      open={open}
      onClose={onClose}
      confirmDisabled={confirmation !== venue.name}
      confirmButtonText={`Delete ${venue.name}`}
      onConfirm={onConfirm}
      maxWidth="sm"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="error" variant="h4">
            Are you sure you want to delete {venue.name}?
          </Typography>
          <Typography color="error">
            This cannot be undone and will delete all associated data.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            If you are really really really sure you want to delete this venue,
            type the name below and click confirm.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Fields.PvTextField
            label="Type Venue Name to Continue"
            name="delete-venue-confirmation"
            value={confirmation}
            onChange={(e) => setConfirmation(e.target.value)}
          />
        </Grid>
      </Grid>
    </PvDialog>
  );
};
