import React from 'react';
import { ApolloConsumer } from '@apollo/client';
import VenuesTable from '../components/VenuesTable';

export default function Venues() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '100%' }}>
        <ApolloConsumer>
          {(apolloClient) => <VenuesTable apolloClient={apolloClient} />}
        </ApolloConsumer>
      </div>
    </div>
  );
}
