import { useTheme } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { RedButton } from './RedButton';
import { DeleteVenueModal } from './DeleteVenueModal';
import { DisconnectStripeModal } from './DisconnectStripeModal';

export const DangerPanel = ({ venue }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);

  const afterConfirm = () => {
    navigate(`/admin/organizations/${venue.organization.id}`);
  }

  return (
    <div style={{ width: '100%', padding: theme.spacing(2) }}>
      <Grid container spacing={2}>
        {venue.stripeAccount && (
          <>
            <Grid item xs={6}>
              <RedButton
                disabled={!venue.stripeAccount}
                fullWidth
                onClick={() => setOpenDisconnectModal(true)}
              >
                Disconnect Stripe Account
              </RedButton>
            </Grid>
            <Grid item xs={6} />
          </>
        )}
        <Grid item xs={6}>
          <RedButton fullWidth onClick={() => setOpenDeleteModal(true)}>
            Delete Venue
          </RedButton>
        </Grid>
      </Grid>
      <DeleteVenueModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        venue={venue}
        afterConfirm={afterConfirm}

      />
      <DisconnectStripeModal
        open={openDisconnectModal}
        onClose={() => setOpenDisconnectModal(false)}
        venue={venue}
      />
    </div>
  );
};
