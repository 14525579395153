import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticatedAdmin } from '../providers/AuthenticatedAdminProvider';
import Pinned from '../components/Pinned';
import NavigationBar from '../components/NavigationBar';

export default () => {
  const { authenticatedAdmin } = useAuthenticatedAdmin();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticatedAdmin) {
      navigate('/admins/sign_in');
    }
  }, [authenticatedAdmin]);

  if (!authenticatedAdmin) {
    return null;
  }

  return (
    <div>
      <NavigationBar authenticatedAdmin={authenticatedAdmin} />
      <Pinned />
      <div style={{ padding: 12 }}>
        <Outlet />
      </div>
    </div>
  );
};
