import { useTheme } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { apiHost } from '@pv/common/api';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

export const EventsPanel = ({ venue }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <div style={{ padding: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            href={`${API_HOST}/admin/venues/${venue?.id}/download_events`}
            fullWidth
            variant="outlined"
          >
            Download Events
          </Button>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              onClick={() =>
                navigate(`/admin/venues/${venue?.id}/data_imports`)
              }
            >
              Check out the new Data Imports tab to upload events in bulk.
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
