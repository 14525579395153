import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Template } from 'devextreme-react/core/template';
import DataGrid, {
  Button as DataGridButton,
  Column,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import join from 'lodash/join';
import compact from 'lodash/compact';
import first from 'lodash/first';
import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { archiveMutation, dataGridQuery } from '@pv/common/graphql';
import { FullScreen, PVSecondaryButton } from '@pv/common/components';
import { moneyPrint } from '@pv/common/utils';
import { gql, useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';

const EXPORT_VENUE_REPORT = gql`
  mutation ExportVenueReport($input: ExportVenueReportInput!) {
    exportVenueReport(input: $input) {
      errors {
        message
      }
    }
  }
`;

const VenuesTable = ({ apolloClient }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dataGrid = useRef();

  const [dataSource, setDataSource] = useState();

  const createStore = () =>
    new CustomStore({
      key: 'id',
      load: async (loadOptions) => {
        let { skip, take, sort, filter: rawFilter } = loadOptions;
        const filter = [];
        rawFilter = rawFilter || [];
        for (let i = 0; i < rawFilter.length; i += 2) {
          const f = rawFilter[i];
          const logical = rawFilter[i + 1];
          filter.push({
            field: f[0],
            operator: f[1],
            operands: [f[2]],
            logical,
          });
        }

        const options = {
          skip,
          take,
          sort,
          filter,
        };
        const variables = { venueIds: [], className: 'Venue', options };
        const fetchPolicy = 'no-cache';
        const query = dataGridQuery;
        const {
          data: { dataGrid },
        } = await apolloClient.query({ query, variables, fetchPolicy });
        let { records, totalCount } = dataGrid;
        records = map(records, (r) => ({
          ...r,
          ...r.subscription,
          tier: startCase(r.subscription?.tier),
          organiaztionName: r.organization.name,
          formattedCustomerStartedAt:
            r.customerStartedAt &&
            moment(r.customerStartedAt).format('MM/DD/YY'),
          formattedBillingInterval: startCase(r.subscription?.billingInterval),
          formattedMonthlyRate: moneyPrint(
            (r.subscription?.monthlyRate || 0) / 100.0
          ),
          createdAt: moment(r.createdAt).format('MM/DD/YY'),
          connectedStripe: !!r.stripeAccount,
          address:
            r.address &&
            join(
              compact([
                r.address.streetAddress1,
                r.address.streetAddress2,
                r.address.city,
                `${r.address.state} ${r.address.zip}`,
                r.address.country,
              ]),
              ', '
            ),
        }));

        return {
          data: records,
          totalCount,
        };
      },
      remove: async (id) => {
        const variables = { input: { id } };
        const mutation = archiveMutation;
        await apolloClient.mutate({ mutation, variables });
        if (dataGrid.current) {
          dataGrid.current.instance.refresh();
        }
      },
    });

  const resetData = () => {
    setDataSource(createStore());
  };

  useEffect(() => {
    resetData();
  }, []);

  const renderTitle = () => <Typography variant="h4">Venues</Typography>;

  const onToolbarPreparing = (e) => {
    const { items } = e.toolbarOptions;
    items.push({
      location: 'after',
      template: 'exportButton',
    });
    items.unshift({
      location: 'before',
      template: 'title',
    });
  };

  const [exportVenueReport] = useMutation(EXPORT_VENUE_REPORT, {
    onCompleted: ({ exportVenueReport }) => {
      enqueueSnackbar('Exported Venue Report', { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar('Error exporting Venue Report', { variant: 'error' });
    },
  });

  const columns = [
    {
      field: 'name',
      header: 'Name',
    },
    {
      field: 'organizationName',
      header: 'Organization',
    },
    {
      field: 'planName',
      header: 'Plan Name',
    },
    {
      field: 'address',
      header: 'Address',
    },
  ];

  const onClickExport = () => {
    exportVenueReport({
      variables: {
        input: {
          venueIds: [],
          orderBy: 'createdAt',
          order: 'asc',
          columns,
        },
      },
    });
  };

  const renderExportButton = () => (
    <PVSecondaryButton
      label="Export"
      marginLeft={theme.spacing(2)}
      onClick={onClickExport}
    />
  );

  const onRowClick = ({ data }) => {
    navigate(`/admin/venues/${data.id}`);
  };
  const cardApplicationFeeRateCellRender = ({ data }) =>
    `${(data.cardApplicationFeeRate * 100).toFixed(2)}%`;

  const achApplicationFeeRateCellRender = ({ data }) =>
    `${(data.achApplicationFeeRate * 100).toFixed(2)}%`;

  const onClickLoginAsUser = ({ row }) => {
    const { data: venue } = row;
    const user = first(venue.users);
    console.log('using magic link: ', user.magicLoginLink);
    window.open(user.magicLoginLink, 'loginasuser');
  };

  return (
    <FullScreen paddingBottom={theme.spacing(3)}>
      {({ height }) => (
        <div>
          <DataGrid
            onRowClick={onRowClick}
            export={{ fileName: 'venues' }}
            style={{ height }}
            ref={dataGrid}
            dataSource={dataSource}
            hoverStateEnabled
            onToolbarPreparing={onToolbarPreparing}
            remoteOperations
            columnAutoWidth
          >
            <SearchPanel visible />
            <Template name="title" render={renderTitle} />
            <Template name="exportButton" render={renderExportButton} />

            <Column dataField="id" allowSearch />
            <Column
              dataField="organiaztionName"
              caption="Organization"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="connectedStripe"
              dataType="boolean"
              allowSearch={false}
              allowSorting={false}
            />
            <Column dataField="name" allowSearch allowSorting />
            <Column
              dataField="basicFeaturesEnabled"
              caption="Basic Features"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="proFeaturesEnabled"
              caption="Pro Features"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="premiumFeaturesEnabled"
              caption="Premium Features"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="websiteUrl"
              allowSearch={false}
              allowSorting={false}
            />
            <Column dataField="email" allowSearch allowSorting />
            <Column
              dataField="tier"
              caption="Plan Name"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="formattedBillingInterval"
              caption="Billing Interval"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="formattedMonthlyRate"
              caption="Monthly Rate"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              caption="Card Processing Rate"
              dataField="cardApplicationFeeRate"
              allowSearch={false}
              allowSorting={false}
              cellRender={cardApplicationFeeRateCellRender}
            />
            <Column
              caption="ACH Processing Rate"
              dataField="achApplicationFeeRate"
              allowSearch={false}
              allowSorting={false}
              cellRender={achApplicationFeeRateCellRender}
            />
            <Column
              dataField="planName"
              caption="Internal Plan Name"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="stripeSubscriptionId"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="achEnabled"
              dataType="boolean"
              allowSearch={false}
            />
            <Column
              dataField="eventCount"
              caption="Events"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="spaceCount"
              caption="Spaces"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="formattedCustomerStartedAt"
              caption="Customer Start Date"
              allowSearch={false}
              allowSorting={false}
            />
            <Column
              dataField="address"
              allowSearch={false}
              allowSorting={false}
            />
            <Column dataField="createdAt" allowSearch={false} allowSorting />
            <Column type="buttons">
              <DataGridButton
                text="Login as User"
                icon="user"
                hint="Login as User"
                onClick={onClickLoginAsUser}
              />
            </Column>
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Paging pageSize={50} />
          </DataGrid>
        </div>
      )}
    </FullScreen>
  );
};

export default VenuesTable;
