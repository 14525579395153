import React, { useState } from 'react';
import { Box, Button } from '@mui/material';

interface FileUploaderProps {
  fileName: string;
  fileType: 'csv' | 'zip';
  file: any;
  label: string;
  color: string;
  onFileAttached: (blob: any) => void;
}

export const FileUploader = ({
  fileName,
  fileType,
  file,
  label,
  color,
  onFileAttached,
}: FileUploaderProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const fileUploadRef = React.useRef<HTMLInputElement>(null);

  const onChangeUploadFile = async (e: any) => {
    setIsUploading(true);
    const files = e.target.files;
    const uploadedFile = Array.from(files).filter((f: any) =>
      f.name.endsWith(fileType)
    )[0];
    if (uploadedFile) {
      onFileAttached(uploadedFile);
    } else {
      onFileAttached(null);
    }
    setIsUploading(false);
  };

  const buttonText = file ? `${label}: ${file.name}` : `Upload ${label}`;

  return (
    <div
      style={{
        display: 'flex',
        margin: '20px 0',
      }}
    >
      <label htmlFor={`${fileName}-input`}>
        <Box>
          <Button
            variant="outlined"
            component="span"
            color={'primary'}
            sx={{
              backgroundColor: color,
            }}
          >
            {buttonText}
          </Button>
        </Box>

        <input
          ref={fileUploadRef}
          id={`${fileName}-input`}
          onChange={onChangeUploadFile}
          name={fileName}
          type="file"
          accept={`.${fileType}`}
          style={{
            position: 'fixed',
            top: '-100em',
          }}
        />
      </label>
    </div>
  );
};
