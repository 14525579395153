import { gql, useQuery } from '@apollo/client';
import LaunchIcon from '@material-ui/icons/Launch';
import copyToClipboard from 'copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from '@pv/common/hooks';
import { useTheme } from '@material-ui/styles';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { Grid, Paper, Button, Chip } from '@material-ui/core';
import { EditablePanel, Fields } from '@pv/common/components';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { appHost } from '@pv/common/api';
const APP_HOST = appHost(process.env.REACT_APP_PV_ENV);

const apiKey = 'AIzaSyCABoBajwP5JARmturb8sgkTyCMft3O3KI';

const collectionFields = gql`
  fragment AdminCollectionFields on Collection {
    name
    slug
    zoom
    latitude
    longitude
  }
`;

const updateCollectionMutation = gql`
  mutation UpdateCollection($input: UpdateCollectionInput!) {
    updateCollection(input: $input) {
      collection {
        id
        ...AdminCollectionFields
      }
      errors {
        message
      }
    }
  }
  ${collectionFields}
`;

const collectionQuery = gql`
  query AdminCollection($id: ID!) {
    collection(id: $id) {
      id
      ...AdminCollectionFields
    }
  }
  ${collectionFields}
`;

const containerStyle = {
  width: '100%',
  height: '800px',
};

const Map = ({
  zoom,
  setZoom,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  editing,
  map,
}) => {
  const onZoomChanged = () => {
    if (map.current) {
      setZoom(map.current.zoom);
    }
  };

  const onCenterChanged = () => {
    if (map.current) {
      const center = map.current.getCenter();
      setLatitude(center.lat());
      setLongitude(center.lng());
    }
  };

  const options = {
    mapTypeControl: false,
    draggable: Boolean(editing),
    scrollwheel: Boolean(editing),
    panControl: Boolean(editing),
    scaleControl: Boolean(editing),
    zoomControl: Boolean(editing),
  };

  if (!zoom || !latitude || !longitude) {
    return null;
  }

  return (
    <div>
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          options={options}
          onLoad={(m) => (map.current = m)}
          mapContainerStyle={containerStyle}
          zoom={zoom}
          onZoomChanged={onZoomChanged}
          onCenterChanged={onCenterChanged}
          center={{ lat: latitude, lng: longitude }}
        ></GoogleMap>
      </LoadScript>
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1, marginRight: 12 }}>Zoom: {zoom}</div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: 12 }}>
            {Math.abs(latitude)}° {latitude > 0 ? 'N' : 'S'}
          </div>
          <div>
            {Math.abs(longitude)}° {longitude > 0 ? 'E' : 'W'}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Collection = () => {
  const theme = useTheme();
  const params = useParams();

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [zoom, setZoom] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const map = useRef();
  const collectionId = params.id;
  const variables = { id: collectionId };
  const { data } = useQuery(collectionQuery, { variables });
  const collection = data?.collection;

  const [updateCollection] = useMutation(updateCollectionMutation, {
    onNoErrorsCompleted: () => {
      enqueueSnackbar('Updated Collection', { variant: 'success' });
    },
  });

  const onSave = () => {
    const input = {
      id: collection.id,
      name,
      slug,
      zoom,
      latitude,
      longitude,
    };
    const variables = { input };
    updateCollection({ variables });
  };

  const resetValues = () => {
    if (collection) {
      setName(collection.name);
      setSlug(collection.slug);
      setZoom(collection.zoom);
      setLatitude(collection.latitude);
      setLongitude(collection.longitude);

      if (map.current) {
        map.current.setZoom(collection.zoom);
        map.current.setCenter({
          lat: collection.latitude,
          lng: collection.longitude,
        });
      }
    }
  };

  useEffect(() => {
    if (collection) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  const onClickIdChip = () => {
    copyToClipboard(collection.id);
    enqueueSnackbar(`Copied ${collection.id} to clipboard`, {
      variant: 'info',
    });
  };

  const onClickLaunch = () => {
    window.open(`${APP_HOST}/collections/${collection.slug}`);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Chip
          clickable
          onClick={onClickIdChip}
          variant="outlined"
          label={`ID ${collection?.id}`}
        />

        <Button onClick={onClickLaunch}>
          <LaunchIcon />
        </Button>
      </div>
      <Paper style={{ padding: theme.spacing(2), position: 'relative' }}>
        <EditablePanel
          onSave={onSave}
          onClear={resetValues}
          title={`Collection: ${collection?.name}`}
        >
          {({ editing }) => (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Fields.PvTextField
                      disabled={!editing}
                      required
                      label="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Fields.PvTextField
                      disabled={!editing}
                      required
                      label="Slug"
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                      name="slug"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Map
                  zoom={zoom}
                  setZoom={setZoom}
                  latitude={latitude}
                  setLatitude={setLatitude}
                  longitude={longitude}
                  setLongitude={setLongitude}
                  editing={editing}
                  map={map}
                />
              </Grid>
            </Grid>
          )}
        </EditablePanel>
      </Paper>
    </div>
  );
};
