import React from 'react';
import { useTheme } from '@material-ui/styles';
import { Chip } from '@material-ui/core';
import { Check as CheckIcon } from 'react-feather';
import { useChipStyles } from '../useChipStyles';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import includes from 'lodash/includes';
import { openInNewTab } from '../../utils';

export const ProposalPaymentsChip = ({ venue }) => {
  const theme = useTheme();

  if (venue?.paymentsEnabled) {
    return <GreenChip label="Proposal Payments Visible" />;
  }
  return <RedChip label="Proposal Payments Hidden" />;
};

export const GreenChip = (props) => {
  const classes = useChipStyles();
  return (
    <Chip
      icon={<CheckIcon />}
      variant="outlined"
      classes={{
        root: classes.greenStatusChip,
        label: classes.chipLabel,
        icon: classes.greenIcon,
      }}
      {...props}
    />
  );
};

export const RedChip = (props) => {
  const classes = useChipStyles();
  return (
    <Chip
      icon={<CloseIcon fontColor="inherit" />}
      variant="outlined"
      classes={{
        root: classes.dangerStatusChip,
        label: classes.chipLabel,
        icon: classes.dangerIcon,
      }}
      {...props}
    />
  );
};

export const StripeChip = ({ venue }) => {
  const label = 'Stripe Connect';
  if (venue.stripeAccount) {
    const stripeAccountId = venue.stripeAccount.stripeAccountId;
    return (
      <GreenChip
        label={label}
        onClick={openInNewTab({
          url: `https://dashboard.stripe.com/connect/accounts/${stripeAccountId}`,
        })}
      />
    );
  }
  return <RedChip label={label} />;
};

export const SquareChip = ({ venue }) => {
  const theme = useTheme();
  const label = 'Square Connected';
  if (venue.squareAccount) {
    return <GreenChip label={label} />;
  }
  return <RedChip label={label} />;
};

export const YellowChip = (props) => {
  const classes = useChipStyles();
  return (
    <Chip
      icon={<WarningIcon fontColor="inherit" />}
      variant="outlined"
      classes={{
        root: classes.warnStatusChip,
        label: classes.chipLabel,
        icon: classes.warnIcon,
      }}
      {...props}
    />
  );
};

export const LegacyChip = ({ subscription }) => {
  const classes = useChipStyles();
  const theme = useTheme();
  const planName = subscription?.planName;
  const isLegacy = includes(['Legacy Plan', 'Legacy Price'], planName);

  return (
    <>
      {isLegacy && (
        <Chip
          icon={
            <WarningIcon
              fontColor="inherit"
              style={{ marginLeft: theme.spacing(1) }}
            />
          }
          variant="outlined"
          label={`On ${planName}`}
          classes={{
            root: classes.warnStatusChip,
            label: classes.chipLabel,
            icon: classes.warnIcon,
          }}
          onClick={openInNewTab({
            url: `https://dashboard.stripe.com/subscriptions/${subscription?.stripeSubscriptionId}`,
          })}
        />
      )}
    </>
  );
};
