import { gql } from '@apollo/client';
import { Button, Divider, Grid } from '@material-ui/core';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@pv/common/hooks';
import { Fields, SidebarHeader, SidebarDrawer } from '@pv/common/components';
import { useNavigate } from 'react-router-dom';

const createCollectionMutation = gql`
  mutation CreateCollection($input: CreateCollectionInput!) {
    createCollection(input: $input) {
      collection {
        id
        name
        slug
        zoom
        latitude
        longitude
      }
      errors {
        message
      }
    }
  }
`;

export const CollectionDrawer = ({ open, onClose, afterConfirm }) => {
  const [name, setName] = useState('');
  const [forceValidate, setForceValidate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setName('');
  }, [open]);

  const [createCollection, { loading }] = useMutation(
    createCollectionMutation,
    {
      onNoErrorsCompleted: ({ createCollection }) => {
        const collection = createCollection.collection;
        enqueueSnackbar('Created Collection', { variant: 'success' });
        navigate(`/admin/collections/${collection.id}`);
        onClose();
      },
    }
  );

  const onSave = () => {
    if (!name) {
      setForceValidate(true);
      return;
    }
    const input = { name };
    const variables = { input };
    createCollection({ variables });
  };

  let title = 'New Collection';

  return (
    <>
      <SidebarDrawer open={open} onClose={onClose}>
        <>
          <div className="collection-drawer">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <SidebarHeader title={title} />
                  <Button
                    disabled={loading}
                    onClick={onSave}
                    size="small"
                    variant="contained"
                    color="secondary"
                  >
                    Save
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Fields.PvTextField
                  required
                  label="Collection Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  forceValidate={forceValidate}
                />
              </Grid>
            </Grid>
          </div>
        </>
      </SidebarDrawer>
    </>
  );
};
