import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { PageLoading, SidebarHeader } from '@pv/common/components';

import { VenueDataImportsList } from './VenueDataImportsList';
import { VenueDataImportForm } from './VenueDataImportForm';
import { Button } from '@mui/material';
interface DataImportsPanelProps {
  venueId: string;
}
interface VenueDataImport {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  errorMessages: string[];
}

const venueDataImportsQuery = gql`
  query VenueDataImportsQuery($venueId: ID!) {
    venueDataImports(venueId: $venueId) {
      id
      createdAt
      updatedAt
      status
      errorMessages
    }
  }
`;

export const DataImportsPanel = ({ venueId }: DataImportsPanelProps) => {
  const [showForm, setShouldShowForm] = useState(false);
  const [venueDataImports, setVenueDataImports] = React.useState<
    VenueDataImport[]
  >([]);

  const { loading, error } = useQuery(venueDataImportsQuery, {
    variables: { venueId: venueId },
    onCompleted: (data) => {
      setVenueDataImports(data.venueDataImports);
    },
  });

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <div>
      <SidebarHeader title="Venue Data Imports" />
      <VenueDataImportsList venueDataImports={venueDataImports} />

      {!showForm && (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setShouldShowForm(true)}
          sx={{
            margin: '10px 0px',
          }}
        >
          Create New Import
        </Button>
      )}

      <VenueDataImportForm
        show={showForm}
        onClose={() => setShouldShowForm(false)}
        venueId={venueId}
        onCompleted={(dataImport) => {
          setVenueDataImports([...venueDataImports, dataImport]);
        }}
      />
    </div>
  );
};
