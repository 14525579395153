import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useAuthenticatedAdmin } from '../providers/AuthenticatedAdminProvider';

export default ({}) => {
  const naviagate = useNavigate();

  const { authenticatedAdmin } = useAuthenticatedAdmin();

  useEffect(() => {
    if (authenticatedAdmin) {
      enqueueSnackbar('You are already logged in', { variant: 'error' });
      naviagate('/admin');
    }
  }, [authenticatedAdmin]);

  if (authenticatedAdmin) {
    return null;
  }

  return <Outlet />;
};
