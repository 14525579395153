import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Point,
} from 'react-simple-maps';
import filter from 'lodash/filter';
import isNumber from 'lodash/isNumber';
import map from 'lodash/map';
import { point, multiPolygon } from '@turf/helpers';
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";

import usaGeoJson from '../assets/usa_geojson.json';

const addressesQuery = gql`
  query Addresses {
    addresses {
      id
      streetAddress1
      streetAddress2
      city
      state
      zip
      country
      latitude
      longitude
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  mapRoot: {
    width: '100%',
  },
}));

export default () => {
  const theme = useTheme();
  const classes = useStyles();
  const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';
  const usaPolygon = multiPolygon(usaGeoJson.coordinates);

  const { data, loading } = useQuery(addressesQuery);
  const addresses = filter(
    data?.addresses,
    (a) => isNumber(a.latitude) && isNumber(a.longitude) && booleanPointInPolygon(point([a.longitude, a.latitude]), usaPolygon)
  );

  const fill = theme.palette.grey[400];
  const stroke = theme.palette.grey[600];
  const strokeWidth = 0.75;
  const outline = 'none';
  const geoStyle = { fill, stroke, strokeWidth, outline };

  return (
    <ComposableMap className={classes.mapRoot} projection="geoAlbersUsa">
      <Geographies geography={geoUrl}>
        {({ geographies, projection }) =>
          geographies.map((geo) => (
            <Geography
              // projection={projection}
              style={{
                default: geoStyle,
                hover: geoStyle,
                pressed: geoStyle,
              }}
              key={geo.rsmKey}
              geography={geo}
            />
          ))
        }
      </Geographies>
      {map(addresses, (address) => {
        const coordinates: Point = [address.longitude, address.latitude];
        return (
          <Marker key={address.id} coordinates={coordinates}>
            <circle
              r={1}
              fill={theme.palette.primary.main}
              stroke={theme.palette.primary.main}
              strokeWidth={1}
            />
          </Marker>
        );
      })}
    </ComposableMap>
  );
};
