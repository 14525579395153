import { gql, useQuery } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { red } from '@material-ui/core/colors';
import moment from 'moment';
import join from 'lodash/join';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import size from 'lodash/size';
import find from 'lodash/find';
import Map from '../components/Map';
import { useMutation } from '@pv/common/hooks';
import { Fields, PVSecondaryButton } from '@pv/common/components';
import { timeSeriesMetricsQuery } from './Venue/graphql';
import { usePublicConfig } from '@pv/common/providers';
import TimeSeriesChart from '../components/TimeSeriesChart';
import { apiHost } from '@pv/common/api';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(-4),
    [theme.breakpoints.down('sm')]: {
      padding: '0px 150px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 50px',
    },
  },
}));

const restartAppMutation = gql`
  mutation RestartApp($input: RestartAppInput!) {
    restartApp(input: $input) {
      errors {
        message
      }
    }
  }
`;

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

const OrgAnalytics = () => {
  const { publicConfig } = usePublicConfig();
  const theme = useTheme();
  const [startDate, setStartDate] = useState(moment().subtract(12, 'month'));
  const [endDate, setEndDate] = useState(moment());
  const [interval, setInterval] = useState('week');
  const [timeSeriesMetricIds, setTimeSeriesMetricIds] = useState([]);
  const { data } = useQuery(timeSeriesMetricsQuery);
  const allTimeSeriesMetrics = data?.timeSeriesMetrics;

  const exportDataUrl = `${API_HOST}${
    publicConfig?.paths?.adminsAnalyticsDownload
  }?${join(
    map(timeSeriesMetricIds, (tsmId) => `time_series_metric_ids[]=${tsmId}`),
    '&'
  )}&start_date=${moment(startDate).format('Y-MM-DD')}&end_date=${moment(
    endDate
  ).format('Y-MM-DD')}&interval=${interval}`;

  useEffect(() => {
    setTimeSeriesMetricIds(map(allTimeSeriesMetrics, (tsm) => tsm.id));
  }, [allTimeSeriesMetrics]);

  const intervalVals = map(['day', 'week', 'month'], (i) => [i, startCase(i)]);

  const timeSeriesMetricVals = map(allTimeSeriesMetrics, (tsm) => [
    tsm.id,
    startCase(tsm.name),
  ]);

  const renderValue = (selected) => {
    if (size(selected) < 3) {
      return join(selected, ', ');
    }
    return `${size(selected)} Metrics`;
  };

  return (
    <div style={{ margin: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              <Fields.PvDateField
                label="Start"
                value={startDate}
                onChange={setStartDate}
              />
            </div>
            <div style={{ marginLeft: theme.spacing(2) }}>
              <Fields.PvDateField
                label="End"
                value={endDate}
                onChange={setEndDate}
              />
            </div>
            <div style={{ marginLeft: theme.spacing(2) }}>
              <Fields.PvDropDown
                label="Interval"
                value={interval}
                onChange={(e) => setInterval(e.target.value)}
                vals={intervalVals}
              />
            </div>
            <div style={{ marginLeft: theme.spacing(2) }}>
              <Fields.PvMultiSelect
                renderValue={renderValue}
                label="Metrics"
                value={timeSeriesMetricIds}
                onChange={(e) => setTimeSeriesMetricIds(e.target.value)}
                vals={timeSeriesMetricVals}
              />
            </div>
            <div style={{ flexGrow: 1 }} />
            <div style={{ marginLeft: theme.spacing(2) }}>
              <PVSecondaryButton label="Export" href={exportDataUrl} />
            </div>
          </div>
        </Grid>
        {map(timeSeriesMetricIds, (timeSeriesMetricId) => {
          const timeSeriesMetric = find(
            allTimeSeriesMetrics,
            (tsm) => tsm.id === timeSeriesMetricId
          );
          return (
            <Grid key={timeSeriesMetricId} item xs={12}>
              <TimeSeriesChart
                timeSeriesMetric={timeSeriesMetric}
                interval={interval}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const Home = () => {
  const classes = useStyles();

  const debounce = { leading: true, trailing: false, timeout: 5000 };
  const [restartApp, { loading }] = useMutation(restartAppMutation, {
    debounce,
  });
  const onClickRestartApp = () => {
    restartApp({ variables: { input: {} } });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Map />
        </Grid>
        <Grid item xs={12} md={8}>
          <OrgAnalytics />
        </Grid>
        <Grid item xs={12}>
          <RedButton
            style={{ marginTop: 100 }}
            onClick={onClickRestartApp}
            disabled={loading}
          >
            Restart App
          </RedButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
