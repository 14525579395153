import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Chip,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import copyToClipboard from 'copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import LaunchIcon from '@material-ui/icons/Launch';
import { useLocation, useNavigate } from 'react-router-dom';
import map from 'lodash/map';
import first from 'lodash/first';
import VenueDrawer from '../components/VenueDrawer';
import { getUIItem, setUIItem } from '@pv/common/utils';
import { useMutation } from '@pv/common/hooks';
import { EditablePanel, Fields, PageLoading } from '@pv/common/components';
import { GreenChip, RedChip } from './Venue/components';
import { openInNewTab } from './utils';

const useStyles = makeStyles((theme) => ({
  name: {
    margin: theme.spacing(2),
  },
  tabWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export const organizationFields = gql`
  fragment OrganizationFields on Organization {
    name
    billingEmail
    stripeLast4
    discount
    stripeCustomerId
    isCcPaymentEnabled
    createdAt
    discount
    venues {
      id
      name
      planName
      cardApplicationFeeRate
      achApplicationFeeRate
      monthlyRate
    }
    users {
      id
      role
      email
      firstName
      lastName
      magicLoginLink
    }
  }
`;

export const updateOrganizationMutation = gql`
  mutation UpdateOrganization2($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
        ...OrganizationFields
      }
      errors {
        message
      }
    }
  }
  ${organizationFields}
`;

const organizationQuery = gql`
  query AdminOrganization($id: ID!) {
    organization(id: $id) {
      id
      ...OrganizationFields
    }
  }
  ${organizationFields}
`;

const StripeCustomerChip = ({ organization }) => {
  if (!organization.stripeCustomerId) {
    return <RedChip label="No Stripe Customer" />;
  }
  return (
    <GreenChip
      label={organization.stripeCustomerId}
      onClick={openInNewTab({
        url: `https://dashboard.stripe.com/customers/${organization.stripeCustomerId}`,
      })}
    />
  );
};

const UsersPanel = ({ organization }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <div style={{ margin: theme.spacing(2) }}>
      {map(organization.users, (u) => (
        <Grid container spacing={2}>
          <Grid item="xs">
            <Button
              variant="contained"
              onClick={() => navigate(`/admin/users/${u.id}`)}
            >
              {u.firstName} {u.lastName} ({u.email}) - {u.role}
            </Button>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

const VenuesPanel = ({ organization, afterConfirm }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openCreateVenueModal, setOpenCreateVenueModal] = useState(false);

  const onClickAddVenue = () => {
    setOpenCreateVenueModal(true);
  };

  return (
    <div style={{ margin: theme.spacing(2) }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }} />
            <Button
              variant="contained"
              color="secondary"
              onClick={onClickAddVenue}
            >
              Add Venue
            </Button>
          </div>
        </Grid>
        {map(organization.venues, (v) => (
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => navigate(`/admin/venues/${v.id}`)}
            >
              {v.name}
            </Button>
          </Grid>
        ))}
      </Grid>
      <VenueDrawer
        refetchVenues={afterConfirm}
        organization={organization}
        open={openCreateVenueModal}
        onClose={() => setOpenCreateVenueModal(false)}
      />
    </div>
  );
};

const InfoPanel = ({ organization }) => {
  const resetValues = () => {};
  const [name, setName] = useState('');
  const [discount, setDiscount] = useState(
    (organization?.discount || 0.0) * 100
  );
  const [isCcPaymentEnabled, setIsCcPaymentEnabled] = useState(false);

  useEffect(() => {
    setName(organization?.name || '');
    setDiscount((organization?.discount || 0.0) * 100);
    setIsCcPaymentEnabled(organization?.isCcPaymentEnabled || false);
  }, [organization]);

  const [updateOrganization, { loading: updateLoading }] = useMutation(
    updateOrganizationMutation,
    {
      onNoErrorsCompleted: () => {
        enqueueSnackbar('Updated Organization');
      },
    }
  );

  const loading = updateLoading;

  const onSave = () => {
    const input = {
      id: organization.id,
      name,
      isCcPaymentEnabled,
      discount: discount ? discount / 100 : null,
    };
    const variables = { input };

    updateOrganization({ variables });
  };

  return (
    <div>
      <EditablePanel
        name="organization"
        onSave={onSave}
        onClear={resetValues}
        loading={loading}
        title="Organization Info"
      >
        {({ editing }) => (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Fields.PvTextField
                disabled={!editing}
                required
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
              />
            </Grid>
            <Grid item xs={6}>
              {/* TODO: Fix field */}
              <Fields.PvPercentageField
                disabled={!editing}
                fullWidth
                clearable
                label="Discount to be applied to all venues"
                name="discount"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={isCcPaymentEnabled}
                    onChange={(e) => setIsCcPaymentEnabled(e.target.checked)}
                    name="is-cc-payment-enabled"
                    disabled={!editing}
                  />
                }
                label={'Credit Card Payment Enabled'}
              />
            </Grid>

            {/* <Grid item xs={6}> */}
            {/*   /!* TODO: Move to venue level *!/ */}
            {/*   <Fields.PvDateField */}
            {/*     disabled={true} */}
            {/*     fullWidth */}
            {/*     clearable */}
            {/*     label="Free Trial Ends At" */}
            {/*     name="free-trial-ends-at" */}
            {/*     value={freeTrialEndsAt} */}
            {/*     onChange={(d) => setFreeTrialEndsAt(d)} */}
            {/*   /> */}
            {/* </Grid> */}
          </Grid>
        )}
      </EditablePanel>
    </div>
  );
};

const TabPanel = ({ children, value, index }) => {
  if (value !== index) {
    return null;
  }
  return <div>{children}</div>;
};

const tabKey = 'org-tab';
const findInitalTab = () => getUIItem(tabKey) || 'info';

const Organization = () => {
  const classes = useStyles();

  const [tab, setTab] = useState(findInitalTab());
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const organizationId = params.id;
  const variables = { id: organizationId };
  const { data, refetch } = useQuery(organizationQuery, { variables });
  const organization = data?.organization;

  const onTabChange = (e, t) => {
    navigate(`/admin/organizations/${organization.id}/${t}`);
    setTab(t);
    setUIItem(tabKey, t);
  };

  useEffect(() => {
    const barePathMatch = location.pathname.match(
      /\/admin\/organizations\/(\d+)$/
    );
    if (barePathMatch) {
      navigate(`/admin/organizations/${barePathMatch[1]}/info`, {
        replace: true,
      });
      setTab('info');
    } else if (location.pathname.match(/\/admin\/organizations\/\d+\/info$/)) {
      setTab('info');
    } else if (location.pathname.match(/\/admin\/organizations\/\d+\/venues/)) {
      setTab('venues');
    } else if (location.pathname.match(/\/admin\/organizations\/\d+\/users/)) {
      setTab('users');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (!organization) {
    return <PageLoading />;
  }

  const onClickLoginAsUser = ({ row }) => {
    const user = first(organization.users);
    window.open(user.magicLoginLink, 'loginasuser');
  };

  const onClickIdChip = () => {
    copyToClipboard(organization.id);
    enqueueSnackbar(`Copied ${organization.id} to clipboard`);
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            clickable
            onClick={onClickIdChip}
            variant="outlined"
            label={`ID ${organization.id}`}
          />
          <Typography className={classes.name} variant="h2">
            Organization: {organization.name}
          </Typography>
          <StripeCustomerChip organization={organization} />
        </div>
        <Button onClick={onClickLoginAsUser}>
          <LaunchIcon />
        </Button>
      </div>
      <Paper>
        <div className={classes.tabWrapper}>
          <div style={{ flexShrink: 1 }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={tab || 'info'}
              onChange={onTabChange}
              className={classes.tabs}
            >
              <Tab value="info" label="Info" />
              <Tab value="venues" label="Venues" />
              <Tab value="users" label="Users" />
            </Tabs>
          </div>
          <div style={{ width: '100%', flexGrow: 1, minHeight: 500 }}>
            <TabPanel value={tab} index="info">
              <InfoPanel organization={organization} />
            </TabPanel>
            <TabPanel value={tab} index="venues">
              <VenuesPanel organization={organization} afterConfirm={refetch} />
            </TabPanel>
            <TabPanel value={tab} index="users">
              <UsersPanel organization={organization} />
            </TabPanel>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default Organization;
