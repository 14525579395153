import React, { useState } from 'react';
import { useAuthErrorMessage } from '@pv/common/hooks';
import { usePublicConfig } from '@pv/common/providers';
import { apiHost } from '@pv/common/api';
import { SignInWithGoogleButton } from '../components/SignInWithGoogleButton';
import { Box, Typography } from '@mui/material';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

const UsernameAndPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useAuthErrorMessage((auth) => {
    if (auth.params?.email) {
      setEmail(auth.params.email);
    }
  });

  return (
    <form
      action={`${API_HOST}/admins/sign_in`}
      acceptCharset="UTF-8"
      method="POST"
    >
      <div>
        <p>Admin Sign In</p>
        <input
          id="admin_email"
          autoFocus="autofocus"
          placeholder="email"
          autocomplete="email"
          type="email"
          name="admin[email]"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <input
          id="admin_password"
          placeholder="password"
          autocomplete="current-password"
          type="password"
          name="admin[password]"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <button type="submit" name="commit">
          Sign In
        </button>
      </div>
    </form>
  );
};

const SamlSignIn = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box>
        <Typography variant="h6">Admin Sign In</Typography>
      </Box>
      <SignInWithGoogleButton href={`${API_HOST}/auth/saml`} />
    </Box>
  );
};

const SignIn = () => {
  const { publicConfig } = usePublicConfig();

  const samlEnabled = publicConfig?.samlEnabled;

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 200 }}>
      {samlEnabled ? <SamlSignIn /> : <UsernameAndPasswordForm />}
    </div>
  );
};
export default SignIn;
