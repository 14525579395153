import { gql, useQuery } from '@apollo/client';
import {
  Backdrop,
  ClickAwayListener,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import { makeStyles, useTheme } from '@material-ui/styles';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import map from 'lodash/map';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import pluralize from 'pluralize';
import { Fields } from '@pv/common/components';

const useStyles = makeStyles((theme) => ({
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 240,
    height: 36,
    padding: theme.spacing(0, 2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  popper: {
    zIndex: theme.zIndex.modal + 100,
  },
  popperContent: {
    width: '100%',
    overflowY: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: 700,
    },
  },
  searchInputWrapper: {
    padding: theme.spacing(2),
  },
  searchResults: {
    maxHeight: 500,
    overflowY: 'scroll',
  },
  backdrop: {
    zIndex: theme.zIndex.modal - 1,
  },
}));

const adminMultiSearchQuery = gql`
  query AdminMultisearch($query: String!) {
    adminMultiSearch(query: $query) {
      id
      query
      totalCount
      searchableDocuments {
        id
        rank
        searchableType
        searchable {
          ... on Organization {
            id
            name
          }
          ... on Venue {
            id
            name
          }
          ... on User {
            id
            firstName
            lastName
            email
          }
          ... on Event {
            id
            name
            startDate
            status
            venue {
              id
            }
          }
        }
      }
    }
  }
`;

const OrganizationResult = ({ searchableDocument, onClick }) => {
  const navigate = useNavigate();
  const { searchable } = searchableDocument;
  return (
    <ListItem className="organization-search-result" button onClick={onClick}>
      <ListItemText
        primary={<Typography>Organization: {searchable.name}</Typography>}
      />
    </ListItem>
  );
};

const VenueResult = ({ searchableDocument, onClick }) => {
  const navigate = useNavigate();
  const { searchable } = searchableDocument;
  return (
    <ListItem className="venue-search-result" button onClick={onClick}>
      <ListItemText
        primary={<Typography>Venue: {searchable.name}</Typography>}
      />
    </ListItem>
  );
};

const UserResult = ({ searchableDocument, onClick }) => {
  const navigate = useNavigate();
  const { searchable } = searchableDocument;
  return (
    <ListItem className="user-search-result" button onClick={onClick}>
      <ListItemText
        primary={
          <Typography>
            User: {searchable.firstName} {searchable.lastName} (
            {searchable.email})
          </Typography>
        }
      />
    </ListItem>
  );
};

const EventResult = ({ searchableDocument, onClose }) => {
  const navigate = useNavigate();
  const { searchable } = searchableDocument;
  const onClick = () => {
    navigate(`/admin/venues/${searchable.venue.id}`);
    onClose();
  };
  return (
    <ListItem className="user-search-result" button onClick={onClick}>
      <ListItemText
        primary={
          <Typography>
            Event: {searchable.name} ({startCase(searchable.status)}){' '}
            {searchable.startDate &&
              `(${moment(searchable.startDate).format('YY-MM-DD')})`}
          </Typography>
        }
      />
    </ListItem>
  );
};

const SearchableDocument = ({ searchableDocument, onClose }) => {
  const navigate = useNavigate();
  const { searchableType } = searchableDocument;
  const { searchable } = searchableDocument;
  const onClick = () => {
    navigate(
      `/admin/${pluralize(lowerCase(searchable.__typename))}/${searchable.id}`
    );
    onClose();
  };
  if (searchableType === 'Organization') {
    return (
      <OrganizationResult
        searchableDocument={searchableDocument}
        onClick={onClick}
      />
    );
  }
  if (searchableType === 'Venue') {
    return (
      <VenueResult searchableDocument={searchableDocument} onClick={onClick} />
    );
  }
  if (searchableType === 'User') {
    return (
      <UserResult searchableDocument={searchableDocument} onClick={onClick} />
    );
  }
  if (searchableType === 'Event') {
    return (
      <EventResult searchableDocument={searchableDocument} onClose={onClose} />
    );
  }
  return null;
};

export default ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [noMatches, setNoMatches] = useState(false);
  const variables = { query };
  const skip = !query;
  const { data, loading } = useQuery(adminMultiSearchQuery, {
    variables,
    skip,
  });

  const adminMultiSearch = data?.adminMultiSearch;
  // const searchableTypeOrder = data?.adminMultiSearch?.searchableTypeOrder;
  const totalCount = adminMultiSearch?.totalCount;
  const searchableDocuments = adminMultiSearch?.searchableDocuments;
  const matchingDocs = totalCount > 0;

  useEffect(() => {
    if (!loading && query) {
      if (matchingDocs) {
        setNoMatches(false);
      } else {
        setNoMatches(true);
      }
    }
  }, [loading, matchingDocs, query]);

  useEffect(() => {
    if (open) {
      setQuery('');
    }
  }, [open]);

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <Popover
        className={classes.popper}
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: smallScreen ? 0 : 200,
          left: window.innerWidth / 2,
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Paper className={classes.popperContent} elevation={3}>
            <div className={classes.searchInputWrapper}>
              <Fields.PvTextField
                autoFocus
                placeholder="Search Anything"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                disableUnderline
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FlashOnIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={onClose}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <Divider />
            <div style={{ height: 4 }}>
              {loading && <LinearProgress variant="query" />}
            </div>
            <div className={classes.searchResults}>
              <List>
                {noMatches && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography>No Matches</Typography>
                  </div>
                )}
                {map(searchableDocuments, (searchableDocument) => (
                  <SearchableDocument
                    searchableDocument={searchableDocument}
                    onClose={onClose}
                  />
                ))}
              </List>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </Backdrop>
  );
};
